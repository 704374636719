import Drawer from '@/components/drawers'
import Loading from '@/components/widgets/Loading.vue'
import PreviewImage from '@/components/widgets/preview-image'
import Vue from "vue";

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array
    },
    uuid: {
      type: String
    },
    status: {
      default: 0,
      type: Number
    },
    role: {
      type: String
    },
    branch: {
      type: String
    }
  },
  components: {
    Loading,
    Drawer,
    PreviewImage
  },
  data() {
    return {
      loading: false,
      skeleton: false,
      imageStatus: false,
      drawerOpened: false,
      images: [],
      fullImage: [],
      showImage: false,
      image: ''
    }
  },
  watch: {
    // observing the variable 'opened' for changes in its value
    'opened'(n, o) {
      if (n) {
        this.drawerOpened = n
        this.onInitData()
      }
    }
  },
  methods: {
    handleImageError() {
      this.imageStatus = true
      this.onExceptionHandler("ไม่สามารถโหลดรูปภาพได้ !")
    },
    confirm(id){
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
          '<div class="d-flex flex-column">'+
          '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
          '<span class="text-base text-left text-gray-500">คุณต้องการจะยืนยันการชำระเงินหรือไม่?</span>'+
          '</div>'+
        '</div>',
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            popup: 'swal2-custom-rounded',
            closeButton: 'text-3xl swal2-custom-close',
            actions: 'justify-content-end',
            contant: 'd-flex flex-row justify-content-around px-2',
            title: 'd-none',
            confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
          },
          cancelButtonColor: '#dc4a38',
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: "ตกลง",
          showCancelButton: true,
          showCloseButton: true
      }).then(async result => {
        if (result.value) { 
          try {
            this.loading = true
            const result = await Vue.prototype.$axios.post(`${Vue.prototype.$host}/api/v1/document/preemption/approvePayment/${this.uuid}`)
            
            this.loading = false
            this.$router.go(this.$router.currentRoute)
          } catch (error) {
            this.loading = false
            this.onExceptionHandler(error.response.data.message);
          }          
        }
      })
    },
    reject(){
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
          '<div class="d-flex flex-column">'+
          '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
          '<span class="text-base text-left text-gray-500">ท่านต้องการปฏิเสธการชำระเงินหรือไม่?</span>'+
          '</div>'+
        '</div>',
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            popup: 'swal2-custom-rounded',
            closeButton: 'text-3xl swal2-custom-close',
            actions: 'justify-content-end',
            contant: 'd-flex flex-row justify-content-around px-2',
            title: 'd-none',
            confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
          },
          cancelButtonColor: '#dc4a38',
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: "ตกลง",
          showCancelButton: true,
          showCloseButton: true
        }
      ).then(async (result) => {
        if (result.value) { 
          try {
            this.loading = true
            const result = await Vue.prototype.$axios.post(`${Vue.prototype.$host}/api/v1/document/preemption/rejectPayment/${this.uuid}`)
            
            this.loading = false

            window.location.reload()
            // if(result.status === 200) {
            //   // this.onInitData()
              
            // }
          } catch (error) {
            this.loading = false
            this.onExceptionHandler(error.response.data.message);
          } 
        }
      })
    },
    onInitData() {
      this.imageStatus = false
      this.skeleton = true
      setTimeout(() => {        
        this.fullImage = []
        const image = this.data
        for (let index = 0; index < image.length; index++) {
          const element = image[index];
          this.fullImage.push({
            name: element.name,
            path: `${Vue.prototype.$host}/api/image-preview/${element.id}`
          })
        }
        this.skeleton = false
      }, 500)
    },
    onCloseDrawer(event) {
      // closing the drawer component.
      this.drawerOpened = false
      this.$emit('close-event', { 
        status: true
      })
    },
    openImage(image) {
      this.image = image
      this.showImage = !this.showImage
    },
    onInitshowImage(e) {
      this.showImage = false
    }
  }
}
